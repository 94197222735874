<template>
  <ProgressSpinner v-if="loading.plans" />
  <div class="surface-ground px-4 md:px-6 lg:px-8" v-else>
    <div class="text-900 font-bold text-6xl mb-4 text-center">Planes</div>
    <div v-if="!user" class="text-900 font-bold text-6l mb-4 text-center">
      El plan Basic está sujeto a un período de prueba de 7 días. En este
      momento debe escoger un plan para poder usar nuestro producto aunque no se
      le cargará nada hasta que active la suscripción.
    </div>
    <!--<div class="text-700 text-xl mb-6 text-center line-height-3"></div>-->

    <div class="grid">
      <div class="col-12 lg:col-6">
        <div class="p-3 h-full">
          <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
          >
            <div class="text-900 font-medium text-xl mb-2">Basic</div>
            <div class="text-600">Plan básico</div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <span class="font-bold text-2xl text-900"
                >{{ basicPlan.basePrice }}€</span
              >
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li
                class="flex align-items-center mb-3"
                v-for="feature in basicPlan.features"
                :key="feature"
              >
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>{{ feature }}</span>
              </li>
            </ul>
            <hr
              class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto"
            />
            <Button
              label="Seleccionar"
              :class="['p-3', 'w-full', (hasBasicPricing && hasBasicPricing) ? 'hidden' : '']"
              :disabled="hasBasicPricing || loading.payment"
              @click="buyBasicPlan"
            ></Button>
            <div :class="(isPricingActive && hasBasicPricing) ? 'pl-2' : 'hidden'">
              <span class="text-lg customer-badge status-proposal" >Plan actual</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="p-3 h-full">
          <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
          >
            <div class="text-900 font-medium text-xl mb-2">Pro</div>
            <div class="text-600">Plan pro</div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <span class="font-bold text-2xl text-900"
                >{{ proPlan.basePrice }}€
                <span v-if="additionalProPlanPrice > 0"
                  >+ {{ additionalProPlanPrice }}€ adicionales</span
                >
              </span>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li
                class="flex align-items-center mb-3"
                v-for="feature in proPlan.features"
                :key="feature"
              >
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>{{
                  feature.replace(
                    '{ extraOrgPrice }',
                    proPlan.additionalOrganizationsPrice
                  )
                }}</span>
              </li>
            </ul>
            <hr
              class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto"
            />
            <div class="field col-12">
              <label for="minmax-buttons"
                >Comprar puntos de venta adicionales</label
              >
              <InputNumber
                class="w-full"
                v-model="additionalOrganizations"
                mode="decimal"
                show-buttons
                :min="0"
              />
            </div>

            <Button
              label="Seleccionar"
              :class="['p-3', 'w-full', hasSameProPricing ? 'hidden' : '']"
              :disabled="hasSameProPricing || loading.payment"
              @click="buyProPlan"
            ></Button>
            <div :class="(isPricingActive && hasSameProPricing) ? 'pl-2' : 'hidden'">
              <span class="text-lg customer-badge status-proposal" >Plan actual</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 text-right pr-4"
        v-if="!loading.payment && isPricingActive"
      >
        <Button @click="showCancelPopup($event)" class="p-button-danger">
          Cancelar plan actual
        </Button>
        <ConfirmPopup>
          <template #message="slotProps">
            <div class="flex p-4">
              <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
              <p class="pl-2">{{ slotProps.message.message }}</p>
            </div>
          </template>
        </ConfirmPopup>
      </div>
    </div>
    <Dialog
      :visible="disableOrgsModal"
      :closable="false"
      :style="{ width: '450px' }"
      header="Desactivar puntos de venta"
      :modal="true"
      class="p-fluid"
    >
      <p>
        Tiene demasiados puntos de venta activos para el plan seleccionado.
        <br />Seleccione hasta un máximo de {{ maxOrganizations }} para cambiar
        de plan.
      </p>
      <div
        class="field-checkbox"
        v-for="organization in user.organizations"
        :key="organization.id"
      >
        <Checkbox
          :inputId="organization.id"
          name="Organization"
          :value="organization.id"
          v-model="newActiveOrgs"
        />
        <label :for="organization.id">{{ organization.name }}</label>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          @click="dismissDisableOrgsModal"
          class="p-button-danger"
        />
        <Button
          label="Activar nuevo plan"
          :disabled="!organizationNumberValid"
          @click="toggleOrgsAndActivatePlan"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import PRICING_TYPES from '../constants/pricingTypes';
import api from '../api/Acutame';

export default {
  data() {
    const additionalOrganizations =
      this.$store.state?.user?.additionalOrganizations ?? 0;
    return {
      loading: {
        payment: false,
        plans: true,
      },
      plans: [],
      activeOrgs: this.$store.state?.user?.activeOrganizations ?? 0,
      currentAdditionalOrganizations: additionalOrganizations,
      additionalOrganizations,
      disableOrgsModal: false,
      maxOrganizations: 0,
      newActiveOrgs: [],
      wantedPlan: null,
    };
  },
  computed: {
    user() {
      return this.$store.state?.user;
    },
    organizationNumberValid() {
      return this.newActiveOrgs.length <= this.maxOrganizations;
    },
    isPricingActive() {
      return this.user?.pricingStatus === 'ACTIVE';
    },
    hasBasicPricing() {
      return (
        this.isPricingActive && this.user?.pricingType === PRICING_TYPES.BASIC
      );
    },
    hasProPricing() {
      return (
        this.isPricingActive && this.user?.pricingType === PRICING_TYPES.PRO
      );
    },
    hasSameProPricing() {
      return (
        this.hasProPricing &&
        this.additionalOrganizations === this.currentAdditionalOrganizations
      );
    },
    basicPlan() {
      return this.plans.find((plan) => plan.planType === PRICING_TYPES.BASIC);
    },
    proPlan() {
      return this.plans.find((plan) => plan.planType === PRICING_TYPES.PRO);
    },
    additionalProPlanPrice() {
      return (
        this.additionalOrganizations * this.proPlan.additionalOrganizationsPrice
      );
    },
  },
  methods: {
    showCancelPopup(event) {
      this.$confirm.require({
        target: event.currentTarget,
        acceptClass: 'p-button-danger',
        acceptLabel: 'Confirmar',
        message: 'Está a punto de cancelar su suscripción a acutame',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelPlan();
        },
      });
    },
    showDisableOrgsModal(wantedPlan) {
      this.newActiveOrgs = this.$store.state.user.organizations
        .filter((org) => org.enabled)
        .map((org) => org.id);
      this.wantedPlan = wantedPlan;
      this.disableOrgsModal = true;
    },
    dismissDisableOrgsModal() {
      this.wantedPlan = null;
      this.disableOrgsModal = false;
    },
    hasEnoughSlots(planSlots, extraSlots = 0) {
      const maxSlots = planSlots + extraSlots;
      this.maxOrganizations = maxSlots;
      return maxSlots >= this.activeOrgs;
    },
    refreshUser() {
      this.$store.dispatch('initialize');
    },
    emitBuyEvent(plan) {
      this.loading.payment = true;
      const planPricing = this.plans.find((p) => p.planType === plan);
      const planAllowsExtraOrgs = !!planPricing.additionalOrganizationsPrice;
      const extraOrgs = planAllowsExtraOrgs ? this.additionalOrganizations : 0;

      let totalAmount = planPricing.basePrice;
      if (planAllowsExtraOrgs) {
        totalAmount += planPricing.additionalOrganizationsPrice * extraOrgs;
      }

      this.$emit('buyPlan', plan, extraOrgs, totalAmount, () => {
        this.refreshUser();
        this.loading.payment = false;
      });
    },
    async toggleOrgsAndActivatePlan() {
      this.loading.payment = true;
      this.disableOrgsModal = false;
      await api.toggleOrganizationsEnabledState(this.newActiveOrgs);
      this.emitBuyEvent(this.wantedPlan);
    },
    buyBasicPlan() {
      if (
        !this.hasEnoughSlots(
          this.plans.find((plan) => plan.planType === PRICING_TYPES.BASIC)
            .maxOrganizations
        )
      ) {
        this.showDisableOrgsModal(PRICING_TYPES.BASIC);
        return;
      }
      this.loading.payment = true;
      this.emitBuyEvent(PRICING_TYPES.BASIC);
    },
    buyProPlan() {
      if (
        !this.hasEnoughSlots(
          this.plans.find((plan) => plan.planType === PRICING_TYPES.PRO)
            .maxOrganizations,
          this.additionalOrganizations
        )
      ) {
        this.showDisableOrgsModal(PRICING_TYPES.PRO);
        return;
      }
      this.loading.payment = true;
      this.emitBuyEvent(PRICING_TYPES.PRO);
    },
    async loadPlans() {
      this.loading.plans = true;
      const plans = await api.getPricingPlans();
      this.plans = plans;
      this.loading.plans = false;
    },
    async cancelPlan() {
      this.loading.payment = true;
      await api.cancelPlan();
      this.$store.commit('cancelPlan');
      this.loading.payment = false;
    },
  },
  mounted() {
    this.loadPlans();
  },
};
</script>
<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>