<template>
  <Toast />
  <div class="card p-fluid" v-if="step === 1">
    <div class="text-center mb-5">
      <img
        :src="`${publicPath}images/logo-white.svg`"
        alt="Image"
        height="100"
      />
      <div class="text-900 text-3xl font-medium mb-3">Regístrate</div>
      <span class="text-600 font-medium line-height-3"
        >¿Ya tienes una cuenta?</span
      >
      <router-link
        class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
        to="/login"
        >Iniciar sessión</router-link
      >
    </div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="firstName">Nombre</label>
          <span class="p-input-icon-left">
            <i class="pi pi-user" />
            <InputText
              v-model="firstName"
              id="firstName"
              type="text"
              :class="{
                'p-invalid': errors?.firstName,
              }"
            />
          </span>
          <small class="p-invalid" v-if="errors?.firstName"
            >El nombre es obligatorio.</small
          >
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="lastName">Apellidos</label>
          <span class="p-input-icon-left">
            <i class="pi pi-user" />
            <InputText
              v-model="lastName"
              id="lastName"
              type="text"
              :class="{
                'p-invalid': errors?.lastName,
              }"
            />
          </span>
          <small class="p-invalid" v-if="errors?.lastName"
            >Los apellidos son obligatorios.</small
          >
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="email">Email</label>
          <span class="p-input-icon-left">
            <i class="pi pi-envelope" />
            <InputText
              v-model="email"
              id="email"
              type="text"
              :class="{
                'p-invalid': errors?.email,
              }"
            />
          </span>
          <small class="p-invalid" v-if="errors?.email"
            >El email es obligatorio.</small
          >
          <small class="p-invalid" v-if="errors?.emailExists"
            >Ya existe una cuenta con este usuario.</small
          >
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="password">Contraseña</label>
          <span class="p-input-icon-left">
            <i class="pi pi-lock" />
            <InputText
              v-model="password"
              id="password"
              type="password"
              :class="{
                'p-invalid': errors?.password,
              }"
            />
          </span>
          <small class="p-invalid" v-if="errors?.password"
            >La contraseña debe tener al menos 8 caracteres.</small
          >
        </div>
      </div>
      <div class="col-12 flex justify-content-end">
        <Button
          :loading="loading"
          label="Crear cuenta"
          @click="onSubmit"
          style="width: auto"
        />
      </div>
    </div>
  </div>
  <div v-else-if="step === 2">
    <div class="flex justify-content-center">
      <img
        :src="`${publicPath}images/logo-white.svg`"
        alt="Image"
        height="100"
      />
    </div>
    <PlanPricing @buyPlan="onCreateMerchant" />
  </div>
</template>

<script>
import MerchantService from '../service/MerchantService';
import PlanPricing from '../components/PlanPricing.vue';

export default {
  components: {
    PlanPricing,
  },
  data() {
    return {
      step: 1,
      merchantService: null,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      loading: false,
      errors: {},
      merchant: null,
      publicPath: process.env.BASE_URL,
    };
  },
  created() {
    this.merchantService = new MerchantService();
  },
  methods: {
    async onSubmit() {
      this.validate();
      if (Object.keys(this.errors).length === 0) {
        this.merchant = {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          password: this.password,
        };
        this.loading = true;
        const isAvailable = await this.merchantService.isEmailAvailable(
          this.email
        );
        if (isAvailable) this.step = 2;
        else this.errors.emailExists = true;
        this.loading = false;
      }
    },
    async onCreateMerchant(planSelected, additionalOrganizations) {
      this.merchant.pricingPlan = planSelected;
      this.merchant.additionalOrganizations = additionalOrganizations;
      try {
        const response = await this.merchantService.createMerchant(
          this.merchant
        );
        const user = await this.merchantService.login(
          response.merchant.email,
          this.password,
          false
        );
        this.$store.commit('setUser', { user });
        if (response.paymentUrl)
          // ensure parent window is redirected if embeded
          window.top.location.assign(response.paymentUrl);
        else this.$router.push('/');
        this.loading = false;
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Ha habido un error creando la cuenta',
          detail: `Ha habido un error creando la cuenta, por favor inténtalo más tarde`,
          life: 3000,
        });
        console.log(error);
      }
    },
    validate() {
      let errors = {};
      if (this.firstName.trim().length < 1) {
        errors.firstName = 'Nombre es un campo obligatorio';
      }
      if (this.lastName.trim().length < 1) {
        errors.lastName = 'Apellidos es un campo obligatorio';
      }
      if (this.email.trim().length < 1) {
        errors.email = 'Email es un campo obligatorio';
      }
      if (this.password.length < 8) {
        errors.password = 'La contraseña debe tener al menos 8 caracteres';
      }
      this.errors = errors;
    },
  },
};
</script>
